<template>
  <div class="mt-4  px-5">
    <h2>Text Editor</h2>
    <v-select
        v-model="selectText"
        :items="comp_Select_Texte"
        item-text="Texte"
        item-value="id"
        label="Text"
        @change="getSingleText()"
        return-object
        outlined
        class="my-4"
    >
      <template slot="selection" slot-scope="data">
        <!-- HTML that describe how select should render selected items -->
        {{ data.item.kurzText }}
      </template>
      <template slot="item" slot-scope="data">
        <!-- HTML that describe how select should render items when the select is open -->
        {{ data.item.kurzText }}
      </template>

    </v-select>
  <editor
      api-key="3yqivr09xo7i95krxcytn121zfdzpwvp5n1e1l7glpf1zs21"
      output-format="html"
      v-model="comp_Editor"
      @onSaveContent="saveText()"
      :init="{
         height: 500,
         menubar: true,
         plugins: [
           'save advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'save | undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
  />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
export default {
  name: 'TextEditor',
  components: {
    'editor': Editor
  },
  data: () => ({
      changedText: {},
      selectText: {},
  }),
  computed: {
    comp_Editor: {
      get() {
        return this.$store.state.AdminApp.SINGLETEXT.Text;
      },
      set(val) {
        this.changedText = val;
      },
    },
    comp_Select_Texte: {
      get() {
        return this.$store.state.AdminApp.Texte;
      },
      set() {},
    },
  },
  mounted() {
    this.getTexte();
  },
  methods: {

    getTexte() {
      this.$store.dispatch('AdminApp/getTexte');
    },
    getSingleText() {
      this.$store.dispatch('AdminApp/getSingleTexte', this.selectText.id);
    },

    saveText() {
      var Data = {
        TextID: this.selectText.id,
        TextTitel: this.selectText.kurzText,
        TextData: this.changedText,
      }
      this.$store.dispatch('AdminApp/setSingleText', Data)
    },

  },
}
</script>

<style scoped>

</style>