var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4  px-5"},[_c('h2',[_vm._v("Text Editor")]),_c('v-select',{staticClass:"my-4",attrs:{"items":_vm.comp_Select_Texte,"item-text":"Texte","item-value":"id","label":"Text","return-object":"","outlined":""},on:{"change":function($event){return _vm.getSingleText()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.kurzText)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.kurzText)+" ")]}}]),model:{value:(_vm.selectText),callback:function ($$v) {_vm.selectText=$$v},expression:"selectText"}}),_c('editor',{attrs:{"api-key":"3yqivr09xo7i95krxcytn121zfdzpwvp5n1e1l7glpf1zs21","output-format":"html","init":{
       height: 500,
       menubar: true,
       plugins: [
         'save advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
       ],
       toolbar:
         'save | undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help'
     }},on:{"onSaveContent":function($event){return _vm.saveText()}},model:{value:(_vm.comp_Editor),callback:function ($$v) {_vm.comp_Editor=$$v},expression:"comp_Editor"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }